<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="模块名称">
        <a-input
            v-decorator="['moduleName', { rules: [{ required: true, message: '请输入模块名称' }], initialValue: item && item.moduleName }]"
        />
      </a-form-item>
      <a-form-item label="地区名称">
        <a-input
            v-decorator="['regionName', { rules: [], initialValue: item && item.regionName }]"
        />
      </a-form-item>
      <a-form-item label="模块类型">
        <a-select
            v-decorator="['moduleType', { rules: [{ required: true, message: '请输入模块类型' }], initialValue: item && item.moduleType }]"
            style="width: 100%;">
          <a-select-option v-for="type in typeList" :key="type.typeCode" :value="type.typeCode">
            {{ type.typeName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="跳转路径">
        <a-input
            v-decorator="['baseUrl', { rules: [{ required: true, message: '请输入跳转路径' }], initialValue: item && item.baseUrl }]"
        />
      </a-form-item>
      <a-form-item label="模块状态">
        <a-input
            v-decorator="['moduleStatus', { rules: [{ required: true, message: '请输入模块状态' }], initialValue: item && item.moduleStatus }]"
        />
      </a-form-item>
      <a-form-item label="等级">
        <a-select
            v-decorator="['level', { rules: [{ required: true, message: '请选择等级' }], initialValue: item && item.level }]"
            style="width: 100%;">
          <a-select-option v-for="level in moduleLevelList" :key="level.level" :value="level.level">
            {{ level.levelCode }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="扩展编码">
        <a-textarea
            v-decorator="['extendsCode', { rules: [{ required: true, message: '请输入扩展编码' }], initialValue: item && item.extendsCode }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {deleteUserCache, updateRoute} from "@/api/route";
import {queryModuleLevelList, queryTypeList, updateModule} from "@/api/module";

export default {
  name: "moduleEdit",
  data() {
    return {
      title: this.item ? '修改模块' : '新增模块',
      visible: false,
      form: this.$form.createForm(this),
      item: {},
      moduleLevelList: [],
      typeList: []
    }
  },
  mounted() {
    this.queryModuleLevelList()
    this.queryTypeList()
  },
  methods: {
    toggleVisible(item) {
      this.item = item
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updateModule({
            ...this.item,
            ...values,
          })

          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    async queryModuleLevelList() {
      this.moduleLevelList = (await queryModuleLevelList()).result
    },
    async queryTypeList() {
      this.typeList = (await queryTypeList()).result
    }
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改模块' : '新增模块'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
