<template>
  <div>
    <a-form layout="inline">
      <a-form-item label="模块名称">
        <a-input v-model="query.moduleName" placeholder="请输入模块名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editModule(undefined)">增加</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 24px;"
             @change="handleTableChange">
      <span slot="allowDerived" slot-scope="text, record">
          <a-popconfirm
            cancel-text="取消"
            :ok-text="record.allowDerived ? '取消派生' : '允许派生'"
            title="确认操作？"
            @confirm="updateDerived(record)"
        >
            <a >{{record.allowDerived ? '是' : '否'}}</a>
          </a-popconfirm>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="editModule(record)">编辑</a>
        <a-divider type="vertical"/>
       <a-popconfirm
           cancel-text="取消"
           ok-text="删除"
           title="确认删除？"
           @confirm="deleteModule(record)"
       >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <module-edit ref="moduleEdit" @success="queryModuleList"/>
  </div>
</template>

<script>

import _ from "lodash";
import {deleteModule, queryModuleLevelList, queryModuleList, updateModuleDerivedApi} from "@/api/module";
import moduleEdit from "./component/moduleEdit";

export default {
  name: "moduleList",
  components: {moduleEdit},
  data() {
    return {
      query: {
        moduleName: undefined
      },
      columns: [
        {
          title: '模块编号',
          dataIndex: 'moduleCode',
          key: 'moduleCode',
        },
        {
          title: '模块名称',
          dataIndex: 'moduleName',
          key: 'moduleName',
        },
        {
          title: '地区名称',
          dataIndex: 'regionName',
          key: 'regionName',
        },
        {
          title: '模块类型',
          dataIndex: 'moduleType',
          key: 'moduleType',
        },
        {
          title: '跳转路径',
          dataIndex: 'baseUrl',
          key: 'baseUrl',
        },
        {
          title: '等级',
          dataIndex: 'levelCode',
          key: 'levelCode',
        },
        {
          title: '父级链',
          dataIndex: 'permissions',
          key: 'permissions',
        },
        {
          title: '扩展编码',
          dataIndex: 'extendsCode',
          key: 'extendsCode',
        },
        {
          title: '是否允许派生',
          dataIndex: 'allowDerived',
          key: 'allowDerived',
          scopedSlots: {customRender: 'allowDerived'},
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      moduleLevelList: []
    }
  },
  async mounted() {
    await this.queryModuleLevelList()
    await this.queryModuleList()
  },
  methods: {
    updateDerived(record) {
      updateModuleDerivedApi({moduleCode: record.moduleCode, allowDerived: !record.allowDerived}).then(res => {
        this.$message.success('操作成功')
        this.queryModuleList()
      })
    },
    editModule(module) {
      this.$refs.moduleEdit.toggleVisible(module)
    },
    async queryModuleList() {
      this.loading = true

      let data = await queryModuleList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryModuleList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryModuleList()
    },
    async deleteModule(module) {
      await deleteModule([module.id])

      this.$message.success('删除成功')
      await this.queryModuleList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (module, index) => {
        return {
          key: module.moduleCode,
          ...module,
          levelCode: _.find(this.moduleLevelList, {level: module.level}).levelCode
        }
      })
    },
    async queryModuleLevelList() {
      this.moduleLevelList = (await queryModuleLevelList()).result
    }
  },
}
</script>

<style scoped>

</style>
